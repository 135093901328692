
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

import Image from '../../assets/img/test-logo2.png';

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-color");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("navbar-color");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-color");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
    <div className="fixed">
    <Container fluid={true} style={{padding: 0}}>
    <div className="highlight">
    <div className="highlight-text">
      highlight text we can run here
    </div>
  </div>

    </Container>
    <Navbar className={classnames("fixed-top justify-content-center", navbarColor)} expand="lg">
      <Container fluid={true}>
        <div className="navbar-translate">
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>

        
            <a href="/index"><img id="logo-navbar-middle" className="mobile" src={Image} width="150" alt="Please Me"/></a>
      </div>
        <Collapse
          className="justify-content-center"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar className="justify-content-start">
          <NavItem>
              <NavLink
                href="/story"
              >
                Story
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://desarae-gabrielle.com/cherry"
                target="_blank"
              >
                The Cherry Diaries
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/index/#comingsoon"
              >
Podcast              </NavLink>
            </NavItem>
          <NavbarBrand
            data-placement="bottom"
            href="/index"
          >
            <a href="/index"><img id="logo-navbar-middle" src={Image} width="200" alt="Logo Thing main logo"/></a>
          </NavbarBrand>
        
            <NavItem>
              <NavLink
                href="/index/#shop"
              >
                Shop
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
              href="/contact"
              >
                Contact
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#footer"
              >
                More
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://desarae-gabrielle.com/cherry"
                target="_blank"
        >
                             <i className="nc-icon  nc-favourite-28"></i>

              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="/index/#community"
              >
                             <i className="nc-icon  nc-single-02"></i>

              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="http://instagram.com/thinkpleaseme"
                target="_blank"
              >
                             <i className="nc-icon  nc-cart-simple"></i>

              </NavLink>
            </NavItem>
          


          </Nav>

          
        </Collapse>
      </Container>
    </Navbar>
    </div>

<div className="modal fade modal-primary" id="loginModal" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
<div className="modal-dialog modal-login">
  <div className="modal-content">
    <div className="card card-login card-plain">
      <div className="modal-header justify-content-center">
        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
          <i className="now-ui-icons ui-1_simple-remove" />
        </button>
        <div className="header header-primary text-center">
          <div className="logo-container">
            <img src="../../../assets/img/now-logo.png" alt="" />
          </div>
        </div>
      </div>
      <div className="modal-body" data-background-color>
        <form className="form" method action>
          <div className="card-body">
            <div className="input-group form-group-no-border input-lg">
              <div className="input-group-prepend">
                <span className="input-group-text"><i className="now-ui-icons users_circle-08" /></span>
              </div>
              <input type="text" className="form-control" placeholder="First Name..." />
            </div>
            <div className="input-group form-group-no-border input-lg">
              <div className="input-group-prepend">
                <span className="input-group-text"><i className="now-ui-icons ui-1_lock-circle-open" /></span>
              </div>
              <input type="text" className="form-control" placeholder="Password..." />
            </div>
          </div>
        </form>
      </div>
      <div className="modal-footer text-center">
        <a href="#pablo" className="btn btn-neutral btn-round btn-lg btn-block">Get Started</a>
      </div>
    </div>
  </div>
</div>
</div>
</>
  );
}

export default IndexNavbar;
