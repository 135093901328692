
import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

import Image1 from '../../assets/img/cherry.png';
import Image2 from '../../assets/img/sexploration.gif';
import Image3 from '../../assets/img/download.png';
import ScrollAnimation from 'react-animate-on-scroll';



// core components

function Podcast() {
  return (
    <>
    <ScrollAnimation animateIn="fadeIn">

      <div
        className="blog-header page-header section-dark chats-bg"
        id="comingsoon"
      >
        <div className="filter" />
        <div className="content-center">
        <Container>
        <Row>
          <div className="col-lg-5 cherry-hosts" style={{color: '#bd2e2e'}}>
              explanation of the cherry chats, maybe highlight some current guests in the season we launch with.
          </div>
          </Row>
        <Row>
          <div className="col-lg-6 cherry-hosts-desc" style={{color: '#bd2e2e'}}>
              with hosts Logan Rocket-Munk & Leelee Buckingham
          </div>
          </Row>
          <Row>
          <div className="col-lg-12 chats-button" style={{color: '#bd2e2e'}}>
              <Button size="lg" className="cherry-button-spotify" style={{float: "left"}}> Listen on Spotify</Button>
          </div>
          </Row>
          <Row>
          <div className="col-lg-12 chats-button" style={{color: '#bd2e2e', paddingTop: "20px"}}>
              <Button size="lg" className="cherry-button-spotify" style={{float: "left"}}>Listen on iTunes</Button>
          </div>
          </Row>
          <Row>
          <div className="col-lg-12 chats-button" style={{color: '#bd2e2e', paddingTop: "20px"}}>
              <Button size="lg" className="cherry-button-spotify" style={{float: "left"}}>Listen on Google</Button>
          </div>
          </Row>
         </Container>
        </div>
       
      </div>
      </ScrollAnimation>

    </>
  );
}

export default Podcast;
