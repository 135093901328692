import React, { useEffect, useState } from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import { Accordion } from './Accordion.js'
import IndexNavbar from '../Navbars/IndexNavbar.js'
import Footer from '../Footers/DemoFooter.js'
import {
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
  Input,
  Row,
  Col
} from 'reactstrap'

function Contact() {
  return (
    <>
      <IndexNavbar />

      <div
        className='blog-header page-header section-dark'
        style={{
          background: '#efcab8',
          paddingTop: '100px'
        }}
      >
        <Container style={{ backgroundColor: '#efcab8' }}>
          <Row className='justify-content-center accordion-row'>
            <Col lg='10' sm='12'>
              <Accordion>
                <Accordion.Item>
                  <Accordion.Header>Shipping</Accordion.Header>
                  <Accordion.Body>
                    description description description
                    <ul>
                      <li>test test test</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item>
                  <Accordion.Header>Returns & Exchanges</Accordion.Header>
                  <Accordion.Body>
                    description description description
                    <ul>
                      <li>test test test</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item>
                  <Accordion.Header>Product Information</Accordion.Header>
                  <Accordion.Body>
                    description description description
                    <ul>
                      <li>test test test</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item>
                  <Accordion.Header>Our Community</Accordion.Header>
                  <Accordion.Body>
                    description description description
                    <ul>
                      <li>test test test</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col
              lg='10'
              sm='12'
              className='justify-content-center contact-text'
            >
              Still have a question?
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col
              lg='10'
              sm='12'
              className='justify-content-center contact-text-two'
            >
              Contact Us!
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col lg='10' sm='12'>
              <input
                placeholder='Name'
                type='text'
                className='form-control contact-bg'
              />
            </Col>
          </Row>
          <Row className='justify-content-center'
            style={{ paddingTop: '20px' }}>
            <Col lg='10' sm='12'>
              <input
                placeholder='Email Address'
                type='text'
                className='form-control contact-bg'
              />
            </Col>
          </Row>
          <Row
            className='justify-content-center'
            style={{ paddingTop: '20px' }}
          >
            <Col lg='10' sm='12' style={{ marginLeft: '15px' }}>
              <textarea
                placeholder='Your Message'
                rows='5'
                className='form-control contact-bg'
              />
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col
              lg={12}
              style={{
                margin: 'auto',
                textAlign: 'center',
                paddingTop: '20px'
              }}
            >
              <button
                type='button'
                className='btn cherry-button-news btn-info'
                style={{ float: 'none' }}
              >
                Submit Your Inquiry
              </button>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  )
}

export default Contact
