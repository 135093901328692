import React from 'react'

// reactstrap components
import { Container, Row, Col } from 'reactstrap'
import Image from '../../assets/img/3.png'
import ScrollAnimation from 'react-animate-on-scroll'

// core components

function Newsletter() {
  return (
    <>
      <ScrollAnimation animateIn='fadeIn'>
        <div
          className='blog-header page-header section-dark'
          style={{
            background:
              '#bd2e2e url(' + require('assets/img/3.png').default + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }}
          id='community'
        >
          <div className='filter' />
          <div className='content-center' style={{ marginTop: 0 }}>
            <Container fluid={true}>
              <div className='background-test'>
                <div className='newsletter-image'>
                  <Row>
                    <Col lg={12} style={{ margin: 'auto' }}>
                      <h2
                        className='title-newsletter'
                        style={{ color: '#bd2e2e' }}
                      >
                        Join the Community
                      </h2>
                      <p
                        className='newsletter-text'
                        style={{ paddingBottom: '20px' }}
                      >
                        Copy here encouraging people to subscribe and be a part
                        of the community.
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12} style={{ margin: 'auto' }}>
                      <div className='input-group-append'>
                        <input
                          placeholder='Email Address'
                          type='text'
                          className='form-control'
                        />
                        <button
                          type='button'
                          className='btn cherry-button-news btn-info'
                        >
                          Subscribe
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </ScrollAnimation>
    </>
  )
}

export default Newsletter
