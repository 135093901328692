
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import ScrollAnimation from 'react-animate-on-scroll';


import InstagramFeed  from 'react-ig-feed'
import 'react-ig-feed/dist/index.css'

// core components

function Instagram() {
  return (
    <>
      <div
        className="instagram-section page-header section-dark"
        style={{
          background:
            "#edc3b0",
            backgroundRepeat: "repeat",
        }}
      >
        <div className="filter" />
        <div className="content-center" style={{marginTop: 0}}>
        <Container fluid={true} className="instagram">
        <ScrollAnimation animateIn="fadeIn">

        <h2 className="title-new" style={{color: '#bd2e2e'}}>@thinkpleaseme</h2>

            <div>
        <InstagramFeed token={"IGQVJVVUJyMjVzTTQ5ZAFFNWE1HQ0NRbUJic0g3NUVxRzhJVHJrbFdkM1AzMlo4M0xsSU00aHlOcnZA6ZAEJJMktUU2JRY3lNdU9rUTM1bG5UWVdyT015UFRTNlhxNC1lc1d4ZAGJlekx3"}  counter="6"/>
        </div>
        </ScrollAnimation>
          </Container>
        </div>
       
      </div>
    </>
  );
}

export default Instagram;
