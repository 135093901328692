
import React, { Component } from "react";
import { Container } from "reactstrap";


// reactstrap components

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import About from "components/Sections/About.js";
import Pillars from "components/Sections/Pillars.js"
import Instagram from "components/Sections/Instagram.js";
import Blog from "components/Sections/Blog.js";
import Newsletter from 'components/Sections/Newsletter.js';
import Podcast from "components/Sections/Podcast.js";
import Products from "components/Sections/Products.js";
import Cart from "components/Sections/Cart.js";
import "components/Sections/shop.css"
import ScrollAnimation from 'react-animate-on-scroll';





class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isCartOpen: false,
      checkout: { lineItems: [] },
      products: [],
      shop: {}
    };

    this.handleCartClose = this.handleCartClose.bind(this);
    this.addVariantToCart = this.addVariantToCart.bind(this);
    this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
    this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
  }

  componentWillMount() {
    console.log(this.props, "props")
    document.documentElement.classList.remove("nav-open");
    document.body.classList.add("index");

    this.props.client.checkout.create().then((res) => {
      this.setState({
        checkout: res,
      });
    });

    this.props.client.product.fetchAll().then((res) => {
      this.setState({
        products: res,
      });
    });

    this.props.client.shop.fetchInfo().then((res) => {
      this.setState({
        shop: res,
      });
    });
  }

  addVariantToCart(variantId, quantity){
    this.setState({
      isCartOpen: true,
    });

    const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}]
    const checkoutId = this.state.checkout.id

    return this.props.client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
      this.setState({
        checkout: res,
      });
    });
  }

  updateQuantityInCart(lineItemId, quantity) {
    const checkoutId = this.state.checkout.id
    const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]

    return this.props.client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
      this.setState({
        checkout: res,
      });
    });
  }

  removeLineItemInCart(lineItemId) {
    const checkoutId = this.state.checkout.id

    return this.props.client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
      this.setState({
        checkout: res,
      });
    });
  }

  handleCartClose() {
    this.setState({
      isCartOpen: false,
    });
  }

 cleanup() {
    document.body.classList.remove("index");
  };

  render() {
    return (

    <>
      <IndexNavbar />
      <IndexHeader />
      <div className="main">
        <About/>
        <ScrollAnimation animateIn="fadeIn">

        <div
        className="page-header section-dark"
        style={{
          background:
            "#edc3b0"
        }}
        id='shop'
      >
        <div className="filter" />
        <div className="content-center">
        <Container fluid={true} className="pillar-container">
        <h2 className="title-new" style={{color: '#bd2e2e'}}>Shop Our Best Sellers</h2>

        <Products
          products={this.state.products}
          client={this.props.client}
          addVariantToCart={this.addVariantToCart}
        />
          </Container>
          </div>
          </div>
          </ScrollAnimation>
        <Pillars/>
       <Blog />
       <Newsletter />
       <Podcast />
        <Instagram />
        <DemoFooter />
      </div>

      <Cart
          checkout={this.state.checkout}
          isCartOpen={this.state.isCartOpen}
          handleCartClose={this.handleCartClose}
          updateQuantityInCart={this.updateQuantityInCart}
          removeLineItemInCart={this.removeLineItemInCart}
        />
    </>
    );
  }
}

export default Index;
