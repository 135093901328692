
import React, {useState, useEffect} from "react";
import ScrollAnimation from 'react-animate-on-scroll';


// reactstrap components
import { Container } from "reactstrap";
import Image1 from '../../assets/img/4.png';
import Image3 from '../../assets/img/6.png';
import Image2 from '../../assets/img/5.png';
import Image4 from '../../assets/img/2.png';





// core components

function Blog() {


  return (
    <>
      <div
        className="blog-header page-header section-dark"
        style={{
          background:
            "#bd2e2e url(" + require("assets/img/grainbg.png").default + ")",
            backgroundRrepeat: 'repeat'
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container fluid={true} className="container-pad">
          <ScrollAnimation animateIn="fadeIn">

              <div className="blog-padding">
 <h2 className="title-new">We're connecting individuals through personal stories, experiences, and testimonies</h2>
    <div className="card-deck">
        <div className="card">
          <img className="card-img-top" src={Image1} alt="Card image cap" />
          <div className="card-body">
            <small className="stigma">By Author    -    HEALTH</small>
            <h5 className="card-title">How To Know Which Sex Toy Is Right For Your Body</h5>
            <div className="card-text">Here’s a shocking truth: the best sex rarely happens under the most carefully curated circumstances. </div>
            <a href="https://desarae-gabrielle.com/cherry/2021/10/07/how-to-know-which-sex-toy-is-right-for-your-body/">
            <button type="button" className="btn btn-outline-info cherry-latest">Read More</button></a>
          </div>
        </div>
        <div className="card" style={{background: "#e79d92"}}>
          <img className="card-img-top" src={Image2} alt="Card image cap" />
          <div className="card-body">
            <small className="stigma">By Author   -   ADVICE</small>
            <h5 className="card-title">How To Have Sex With Someone New After A Longterm Breakup</h5>
            <div className="card-text">The end of a relationship era can send anyone headlong into a magnificent slutty phase.</div>
            <a href="https://desarae-gabrielle.com/cherry/2021/10/07/how-to-have-sex-with-someone-new-after-a-longterm-breakup/">
            <button type="button" className="btn btn-outline-info cherry-latest">Read More</button></a>
             </div>
        </div>
        <div className="card">
          <img className="card-img-top" src={Image4} alt="Card image cap" />
          <div className="card-body">
            <small className="stigma">By Author   -    REVIEW</small>
            <h5 className="card-title">Foria Wellness Tonic With CBD + Bath Salts With CBD</h5>
            <div className="card-text">A pioneer in the cannabis and sexual wellness space, Foria launched the OG “weed lube” back in 2013.</div>
            <a href="https://desarae-gabrielle.com/cherry/2021/10/07/review-foria-wellness-tonic-with-cbd-bath-salts-with-cbd/">
            <button type="button" className="btn btn-outline-info cherry-latest">Read More</button></a>

          </div>
        </div>
        <div className="card" style={{background: "#e79d92"}}>
          <img className="card-img-top" src={Image3} alt="Card image cap" />
          <div className="card-body">
            <small className="stigma">By Author   -    ADVICE</small>
            <h5 className="card-title">How To Get Comfortable Having Sex With The Lights On</h5>
            <div className="card-text">Here’s a shocking truth: the best sex rarely happens under the most carefully curated circumstances. </div>
            <a href="https://desarae-gabrielle.com/cherry/2021/10/07/how-to-get-comfortable-having-sex-with-the-lights-on/">
            <button type="button" className="btn btn-outline-info cherry-latest">Read More</button></a>

          </div>
        </div>
      </div>
            <div className="content-center" style={{textAlign: "center"}}>

<button type="button" className="btn btn-outline-info btn-lg cherry-button header-btn">Read More @ The Cherry Diaries</button>
</div>
            </div>
            </ScrollAnimation>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Blog;
