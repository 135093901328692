
import React from "react";

// reactstrap components
import { Container, Button } from "reactstrap";

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/back-please.png").default + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <h1 className="presentation-title">Find Your Story, Feel Heard</h1>
               <Button className="header-button header-btn"><a className="reads" href="/index/#shop">Shop Now</a></Button>
               <Button className="header-button header-btn margin-read"><a className="reads"  href="/index/#blog">Read Now </a></Button>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
