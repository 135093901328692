
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



import Image1 from '../../assets/img/authenticity.gif';
import Image2 from '../../assets/img/sexploration.gif';
import Image3 from '../../assets/img/Community.png';


// core components

function Pillars() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          background:
            "#e99d93"
        }}
      >
        <div className="filter" />
        <div className="content-center">
        <Container fluid={true} className="pillar-container">
        <ScrollAnimation animateIn="fadeIn">

        <h2 className="title-new" style={{color: '#bd2e2e'}}>Our Pillars</h2>
        <div className="row justify-content-md-center articles" style={{color: '#bd2e2e', fontFamily: 'Verona'}}> 


          <div className="col-md-4 col-lg-4" style={{color: '#bd2e2e'}}><h5 className="pillars" style={{color: '#bd2e2e', paddingBottom: '2rem'}}>Authenticity</h5>
          <ScrollAnimation animateIn="fadeIn" delay={4}>

          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
            <img className="pillar-height" src={Image1} style={{display: 'block', width: '50%', margin: 'auto'}} />
            </div>
            <p className="pillar-paragraph" style={{color: '#bd2e2e',  fontSize: '24px'}}> We believe the only way to achieve change is through transparency with oneself and others.</p>
            </ScrollAnimation>

          </div>
          <div className="col-md-4 col-lg-4" style={{color: '#bd2e2e'}}>
          <ScrollAnimation animateIn="fadeIn" delay={7}>

            <h5 className="pillars" style={{color: '#bd2e2e', paddingBottom: '2rem'}}>Sexploration</h5>
            <div className="pillar-height" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
              <img src={Image2} style={{display: 'block', width: '50%', margin: 'auto'}} />
            </div>
            <p className="pillar-paragraph" style={{color: '#bd2e2e', fontSize: '24px'}}>We believe that it is essential to understand your body and its differences before truly pleasuring yourself… or anybody else!</p>
            </ScrollAnimation>

         </div>
          <div className="col-md-4 col-lg-4" style={{color: '#bd2e2e'}}><h5 className="pillars"  style={{color: '#bd2e2e', paddingBottom: '2rem'}}> Community</h5>
          <ScrollAnimation animateIn="fadeIn" delay={10}>

            <div className="pillar-height" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
              <img src={Image3} style={{display: 'block', width: '50%', margin: 'auto'}} />
            </div>
            <p className="pillar-paragraph" style={{color: '#bd2e2e', fontSize: '24px'}}>We believe that by coming together, we will be able to build a network of stories, resources, and tools which will significantly impact the lives of women everywhere. </p>
            </ScrollAnimation>

          </div>
        </div>
</ScrollAnimation>
          </Container>
        </div>
       
      </div>
    </>
  );
}

export default Pillars;
