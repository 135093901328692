
import React, { useEffect, useState } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import IndexNavbar from '../Navbars/IndexNavbar.js'
import Footer from '../Footers/DemoFooter.js'
import { Container, Row, Col } from "reactstrap";

import Image1 from '../../assets/img/Manufactured.png';
import Image3 from '../../assets/img/website.png';
import Image2 from '../../assets/img/team.png';
import Image4 from '../../assets/img/inception.png';
import Image5 from '../../assets/img/product.png';
import Founders from '../../assets/img/founders.png';
import placeholder from '../../assets/img/placeholder.png';



function Story() {
  return (
    <>
      <IndexNavbar />

      <div
        className='blog-header page-header section-dark'
        style={{
          background: '#efcab8',
          paddingTop: '100px',
          paddingBottom: "30px",
          fontSize: "20px",
          fontFamily: "Montserrat, Helvetica, Arial, sans-serif"
        }}
      >
        <Container style={{ backgroundColor: '#efcab8', color: "#231f20" }}>
          <Row className='justify-content-center'>
            <Col lg='10' sm='12'>
            <img className="img-fluid" src={Founders} alt="Leelee and Logan" />
              <br></br>
              Leelee Buckingham and Logan Rocket started Please Me to create a
              hub for all things related to sex and female pleasure. Please Me
              is a safe space for you to find information, share personal
              stories, foster connections and ultimately maximize your sexual
              pleasure. Leelee and Logan believe it is essential to learn about
              and embrace our bodies’ differences before trying to achieve
              sexual pleasure, and they want to help everyone do just that. They
              themselves are all still learning, so why not learn together?
              <br></br>
              <br></br>
              Leelee and Logan are currently designing premium products that are
              bound to maximize your sexual arousal. These products will soon be
              featured in Please Me’s shop section, so stay tuned! 
              <br></br>
              <br></br>
              For years, Leelee and Logan have struggled to find answers regarding sexual
              dysfunction and pleasure. They quickly realized we are all being
              failed by the lack of resources, which causes many women to feel
              incredibly alienated. Leelee and Logan knew they had to fix this
              common, detrimental feeling.
              <br></br>
              <br></br>
              Please Me: Find your story. Feel Heard.
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          background: '#e89d93',
          paddingBottom: "20px",
          paddingTop: "20px",
          color: "#bd2e2e"
        }}
      >
        <Container fluid>
        <Row className='justify-content-center' style={{fontSize: "24px", fontWeight: "bold"}}>
        Please Me Timeline
        </Row>
          <Row
            className='justify-content-center'
            style={{fontFamily: "Montserrat, Helvetica, Arial, sans-serif"}}
          >
            <Col lg='2' sm='12' style={{textAlign: "center"}}>
            <img className="img-fluid" src={Image4} alt="Product Inception" />
             Please Me Founded
             <br></br>
             2021
            </Col>
            <Col lg='2' sm='12' style={{textAlign: "center"}}>
            <img className="img-fluid" src={Image2} alt="Team Built" />
             Founding Team
             <br></br>
             2021
            </Col>
            <Col lg='2' sm='12' style={{textAlign: "center"}}>
            <img className="img-fluid" src={Image1} alt="Product Manfuctured" />
            Prototype Manufactured
            <br></br>
            2021
            </Col>
            <Col lg='2' sm='12' style={{textAlign: "center"}}>
            <img className="img-fluid" src={Image3} alt="Website Launched" />
            Website Launched
            <br></br>
            2022
            </Col>
            <Col lg='2' sm='12' style={{textAlign: "center"}}>
            <img className="img-fluid" src={Image5} alt="Product Launched" />
            Product Launched
            <br></br>
            2022
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          background: '#efcab8',
          paddingTop: "40px",
        }}
      >
        <Container>
        <Row className='justify-content-center' style={{fontSize: "24px", fontWeight: "bold", color: "#231f20"}}>
        Please Me Team
        </Row>
        <Row
            className='justify-content-center accordion-row'
            style={{ paddingTop: '40px', paddingBottom: "50px", color: "#231f20",  fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
        }}
          >
             <Col lg='4' sm='12' style={{textAlign: "center"}} >
            <img className="img-fluid" src={placeholder} alt="Product Inception" />
             Logan Munk
             <br></br>
             Founder
            </Col>
            <Col lg='4' sm='12' style={{textAlign: "center"}}>
            <img className="img-fluid" src={placeholder} alt="Team Built" />
             Leelee Buckingham
             <br></br>
             Founder
            </Col>
            <Col lg='4' sm='12' style={{textAlign: "center"}}>
            <img className="img-fluid" src={placeholder} alt="Product Manfuctured" />
            Desarae Gabrielle
            <br></br>
            Chief Technology Officer
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  )
}

export default Story;
