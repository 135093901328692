
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
import Index from "views/Index.js";
import Contact from "components/Sections/Contact.js";
import Story from "components/Sections/Story.js";

import Client from 'shopify-buy';
const client = Client.buildClient({
  storefrontAccessToken: 'bed4a4c62cb92fd8a25c177457fccba6',
  domain: 'please-me-products.myshopify.com'
});

ReactDOM.render(
  <BrowserRouter client={client}>
    <Switch>
      <Route path="/index" render={(props) => <Index {...props} client={client} />} />
      <Route path="/contact" render={(props) => <Contact {...props} client={client} />} />
      <Route path="/story" render={(props) => <Story {...props} client={client} />} />

      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
