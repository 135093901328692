
import React, { useEffect, useState } from "react";
import ScrollAnimation from 'react-animate-on-scroll';


// reactstrap components
import { Container } from "reactstrap";
import Image from "../../assets/img/cherry_right.png"


// core components

function About() {
  return (
    <>
      <div
        className="page-header section-dark swing"
        style={{
          background:
            "#bd2e2e url(" + require("assets/img/grainbg.png").default + ")",
            backgroundRepeat: 'repeat'
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
          <ScrollAnimation animateIn="fadeIn">

          <h2 className="title-new">About Please Me</h2>
              <h5 className="description">Enhancing satisfaction, intimacy, and pleasure during sex goes beyond general practices. 
                <br />We believe that sharing personal stories, experiences, and testimonies contributes to bridging the gap, eliminating the stigma, and connecting individuals.
                <br /><br />
                We strive to dismiss the taboo associated with sexual dysfunctions in women by establishing a safe community and providing factual and firsthand information to ensure that no individual feels dismissed, overlooked or invalidated.
                <br /><br />
                Please Me: Find Your Story, Feel Heard.</h5>
                <div style={{textAlign: "center", paddingTop: "2rem"}}>

                        <div className="col-md-12 col-lg-12 animated_right swing" id ="animated-example"> </div>
                        </div>

            </ScrollAnimation>

          </Container>
        </div>
       
      </div>
    </>
  );
}

export default About;
